
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CommonUtils from '@/utils/common-utils';

const grades = namespace('grades');
const settings = namespace('settings');
const classes = namespace('classes');

@Component
export default class ScoreWeightDialog extends Vue {
    @Prop({ type: Boolean, required: true })
    value!: boolean;

    @Prop({ type: Number, required: false, default: -1 })
    courseId!: number;

    @Prop({ type: String, required: false, default: '' })
    courseName!: string;

    get localValue() {
      return this.value;
    }

    set localValue(val: boolean) {
      this.$emit('close');
    }

    @settings.Getter
    getSettings!: any;

    @settings.Getter('getShowSnackbarNotifications')
    showSnackbarNotifications!: boolean;

    @grades.Action
    updateScoreWeights!: (params?: any) => Promise<any>;

    @classes.Getter
    getClassItems!: Array<any>;

    assignmentWeights: any = [];
    assessmentWeights: any = [];
    standardWeights: any = [];

    filteredAssignmentWeights: any = [];
    filteredAssessmentWeights: any = [];
    filteredStandardWeights: any = [];

    showPercentageError = false;

    classList:Array<any> = [];
    selectedClass: any = '';

    @Watch('selectedClass')
    changeCourse() {
      this.filteredAssignmentWeights = this.assignmentWeights.filter((w: any) => w.subjectId === this.selectedClass.value);
      this.filteredAssessmentWeights = this.assessmentWeights.filter((w: any) => w.subjectId === this.selectedClass.value);
      this.filteredStandardWeights = this.standardWeights.filter((w: any) => w.subjectId === this.selectedClass.value);
    }

    @Watch('assignmentWeights')
    refilterAssignments() {
      this.filteredAssignmentWeights = this.assignmentWeights.filter((w: any) => w.subjectId === this.selectedClass.value);
    }

    @Watch('assessmentWeights')
    refilterAssessments() {
      this.filteredAssessmentWeights = this.assessmentWeights.filter((w: any) => w.subjectId === this.selectedClass.value);
    }

    @Watch('standardWeights')
    refilterStandards() {
      this.filteredStandardWeights = this.standardWeights.filter((w: any) => w.subjectId === this.selectedClass.value);
    }

    nameRulesAssignment = [
      (v: any) => !!v || this.$t('scoreWeightingErrorMsg1')
    ]

    nameRulesAssessment = [
      (v: any) => !!v || this.$t('scoreWeightingErrorMsg4')
    ]

    percentRulesAssignment = [
      (v: any) => (!!v || parseInt(v) === 0) || this.$t('scoreWeightingErrorMsg2'),
      (v: any) => (!isNaN(Number(v))) || this.$t('scoreWeightingErrorMsg3'),
      (v: any) => (parseInt(v) >= 0 && parseInt(v) <= 100) || this.$t('scoreWeightingErrorMsg6')
    ]

    percentRulesAssessment = [
      (v: any) => (!!v || parseInt(v) === 0) || this.$t('scoreWeightingErrorMsg5'),
      (v: any) => (!isNaN(Number(v))) || this.$t('scoreWeightingErrorMsg3'),
      (v: any) => (parseInt(v) >= 0 && parseInt(v) <= 100) || this.$t('scoreWeightingErrorMsg6')
    ]

    get areAssignmentWeights() {
      return CommonUtils.isNotEmpty(this.filteredAssignmentWeights);
    }

    get areAssessmentWeights() {
      return CommonUtils.isNotEmpty(this.filteredAssessmentWeights);
    }

    get areStandardWeights() {
      return CommonUtils.isNotEmpty(this.filteredStandardWeights);
    }

    get areScoreWeights() {
      return this.areAssignmentWeights || this.areAssessmentWeights || this.areStandardWeights;
    }

    @Watch('value')
    initOnOpen() {
      if (this.value) {
        this.refresh();
      }
    }

    refresh() {
      CommonUtils.showLoading();
      this.loadClasses();
      this.showPercentageError = false;
      this.assessmentWeights = [];
      this.assignmentWeights = [];
      const scoreWeights = this.getSettings.accountSettings.scoreWeights;
      this.standardWeights = this.getSettings.accountSettings.standardWeights;
      for (const i in scoreWeights) {
        if (scoreWeights[i].type === 'm') {
          this.addAssessmentWeight(scoreWeights[i].subjectId, scoreWeights[i].name, scoreWeights[i].percentage, scoreWeights[i].scoreWeightId)
        } else {
          this.addAssignmentWeight(scoreWeights[i].subjectId, scoreWeights[i].name, scoreWeights[i].percentage, scoreWeights[i].scoreWeightId)
        }
      }
      this.standardWeights = this.getSettings.accountSettings.standardWeights.map((s: any) => {
        s.scoreWeightId = s.standardScoreWeightId
        return s
      })
      CommonUtils.hideLoading();
    }

    loadClasses() {
      this.classList = [{ value: 0, text: this.$t('allClassesLabel') }].concat(this.getClassItems);
      this.selectedClass = this.classList[0];
    }

    saveScoreWeights() {
      let total = 0;
      for (const i in this.filteredAssessmentWeights) {
        total += +this.filteredAssessmentWeights[i].percentage
      }
      for (const i in this.filteredAssignmentWeights) {
        total += +this.filteredAssignmentWeights[i].percentage
      }
      if (total !== 100 && total !== 0) {
        this.showPercentageError = true;
        return;
      } else {
        this.showPercentageError = false;
      }

      const form: any = this.$refs.letterGradeForm;
      if (form.validate()) {
        CommonUtils.showLoading();
        const payload: any = {
          assignmentWeights: this.filteredAssignmentWeights,
          assessmentWeights: this.filteredAssessmentWeights,
          standardWeights: this.filteredStandardWeights
        }
        if (this.courseId > 0) {
          payload.subjectId = this.courseId;
        } else if (this.courseId < 0 && this.selectedClass.value !== 0) {
          payload.subjectId = this.selectedClass.value;
        }
        this.updateScoreWeights(payload).then(() => {
          CommonUtils.hideLoading();
          this.localValue = false;

          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg45') as string);
          }

          return Promise.resolve();
        });
      }
    }

    addAssignmentWeight(subjectId?: any, name?: any, percentage?: any, id?: any) {
      const newWeight = {
        subjectId: subjectId || this.selectedClass.value,
        scoreWeightId: id || 0,
        name: name || '',
        percentage: percentage || ''
      }
      this.assignmentWeights.push(newWeight);
    }

    addAssessmentWeight(subjectId?: any, name?: any, percentage?: any, id?: any) {
      const newWeight = {
        subjectId: subjectId || this.selectedClass.value,
        scoreWeightId: id || 0,
        name: name || '',
        percentage: percentage || ''
      }
      this.assessmentWeights.push(newWeight);
    }

    addStandardWeight(subjectId?: any, code?: any, name?: any, id?: any) {
      const newWeight = {
        subjectId: subjectId || this.selectedClass.value,
        scoreWeightId: id || 0,
        code: code || '',
        name: name || ''
      }
      this.standardWeights.push(newWeight);
    }

    addSampleScoreWeights() {
      const value = this.selectedClass.value;
      this.addAssignmentWeight(value, 'Homework', '10');
      this.addAssignmentWeight(value, 'Project', '20');
      this.addAssessmentWeight(value, 'Quiz', '30');
      this.addAssessmentWeight(value, 'Exam', '40');
      this.addStandardWeight(value, 'D', 'Does not meet');
      this.addStandardWeight(value, 'M', 'Meets');
      this.addStandardWeight(value, 'E', 'Exceeds');
    }

    removeAssignment(param: any) {
      this.filteredAssignmentWeights.splice(param, 1);
      this.assignmentWeights = this.assignmentWeights.filter((w: any) => w.subjectId !== this.selectedClass.value).concat(this.filteredAssignmentWeights);
    }

    removeAssessment(param: any) {
      this.filteredAssessmentWeights.splice(param, 1);
      this.assessmentWeights = this.assessmentWeights.filter((w: any) => w.subjectId !== this.selectedClass.value).concat(this.filteredAssessmentWeights);
    }

    removeStandard(param: any) {
      this.filteredStandardWeights.splice(param, 1);
      this.standardWeights = this.standardWeights.filter((w: any) => w.subjectId !== this.selectedClass.value).concat(this.filteredStandardWeights);
    }

    clearAssignmentWeights() {
      this.assignmentWeights = this.assignmentWeights.filter((w: any) => w.subjectId !== this.selectedClass.value);
      this.filteredAssignmentWeights = [];
    }

    clearAssessmentWeights() {
      this.assessmentWeights = this.assessmentWeights.filter((w: any) => w.subjectId !== this.selectedClass.value);
      this.filteredAssessmentWeights = [];
    }

    clearStandardWeights() {
      this.standardWeights = this.standardWeights.filter((w: any) => w.subjectId !== this.selectedClass.value);
      this.filteredStandardWeights = [];
    }
}
