var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pb-base-modal",
    {
      attrs: { maxWidth: 500, expandable: false, withActions: false },
      on: { apply: _vm.saveScoreWeights },
      scopedSlots: _vm._u(
        [
          _vm.courseId == 0
            ? {
                key: "header",
                fn: function() {
                  return [_vm._v(_vm._s(_vm.$t("scoreWeightAllLabel")))]
                },
                proxy: true
              }
            : _vm.courseId > 0
            ? {
                key: "header",
                fn: function() {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.$t("scoreWeightClassLabel", {
                          class: _vm.courseName
                        })
                      )
                    )
                  ]
                },
                proxy: true
              }
            : {
                key: "header",
                fn: function() {
                  return [_vm._v(_vm._s(_vm.$t("scoreWeightLabel")))]
                },
                proxy: true
              }
        ],
        null,
        true
      ),
      model: {
        value: _vm.localValue,
        callback: function($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue"
      }
    },
    [
      _c(
        "div",
        { staticClass: "editor" },
        [
          _c(
            "v-form",
            { ref: "letterGradeForm", attrs: { "lazy-validation": "" } },
            [
              _vm.showPercentageError
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mb-0",
                      attrs: { dense: "", border: "left", type: "error" }
                    },
                    [_vm._v(_vm._s(_vm.$t("scoreWeightingErrorMsg7")))]
                  )
                : _vm._e(),
              _vm.courseId < 0
                ? _c("v-select", {
                    staticClass: "mx-5 pb-3 pt-3",
                    attrs: {
                      label: _vm.$t("classLabel"),
                      dense: "",
                      "display:inline-flex": "",
                      "return-object": "",
                      "hide-details": "auto",
                      items: _vm.classList,
                      outlined: ""
                    },
                    model: {
                      value: _vm.selectedClass,
                      callback: function($$v) {
                        _vm.selectedClass = $$v
                      },
                      expression: "selectedClass"
                    }
                  })
                : _vm._e(),
              _c("div", { staticClass: "expanded-editor-section-container" }, [
                !_vm.areScoreWeights
                  ? _c(
                      "div",
                      { staticClass: "editor-section-container-header mb-5" },
                      [
                        _c("div", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm.$t("sampleScoreWeights")))
                        ]),
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "", outlined: "" },
                            on: { click: _vm.addSampleScoreWeights }
                          },
                          [
                            _c("div", [
                              _vm._v(_vm._s(_vm.$t("clickHereLabel")))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "editor-section-container-header" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("assignmentsLabel")))
                  ]),
                  _c(
                    "div",
                    { staticClass: "actions" },
                    [
                      _vm.areAssignmentWeights
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                "aria-label": _vm.$t("clearAllLabel", {
                                  text: _vm.$t("assignmentWeight")
                                })
                              },
                              on: { click: _vm.clearAssignmentWeights }
                            },
                            [_c("v-icon", [_vm._v("fal fa-trash-alt")])],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            "aria-label": _vm.$t("addItemLabel", {
                              text: _vm.$t("assignmentWeight")
                            })
                          },
                          on: {
                            click: function($event) {
                              return _vm.addAssignmentWeight()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("fal fa-plus")])],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "editor-section-container-body px-5 mt-4 mb-4"
                  },
                  [
                    _vm.areAssignmentWeights
                      ? _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "8" } }, [
                              _c("div", [_vm._v(_vm._s(_vm.$t("typeLabel")))])
                            ]),
                            _c("v-col", { attrs: { cols: "4" } }, [
                              _c("div", [
                                _vm._v(_vm._s(_vm.$t("percentageLabel")))
                              ])
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(_vm.filteredAssignmentWeights, function(weight, i) {
                      return _c(
                        "v-row",
                        { key: i, staticClass: "mb-0 mt-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-1 pt-1 pr-1",
                              attrs: { cols: "8" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "auto",
                                  rules: _vm.nameRulesAssignment,
                                  outlined: "",
                                  dense: "",
                                  "aria-label":
                                    _vm.$t("assignmentWeight") +
                                    " " +
                                    _vm.$t("typeLabel") +
                                    " " +
                                    i
                                },
                                model: {
                                  value: weight.name,
                                  callback: function($$v) {
                                    _vm.$set(weight, "name", $$v)
                                  },
                                  expression: "weight.name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-1 pt-1 pl-1",
                              attrs: { cols: "4" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "append-outer-icon": "fal fa-trash-alt",
                                  "hide-details": "auto",
                                  rules: _vm.percentRulesAssignment,
                                  outlined: "",
                                  dense: "",
                                  "aria-label":
                                    _vm.$t("assignmentWeight") +
                                    " " +
                                    _vm.$t("percentageLabel") +
                                    " " +
                                    i
                                },
                                on: {
                                  "click:append-outer": function($event) {
                                    return _vm.removeAssignment(i)
                                  }
                                },
                                model: {
                                  value: weight.percentage,
                                  callback: function($$v) {
                                    _vm.$set(weight, "percentage", $$v)
                                  },
                                  expression: "weight.percentage"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                ),
                _c("div", { staticClass: "editor-section-container-header" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("assessmentsLabel")))
                  ]),
                  _c(
                    "div",
                    { staticClass: "actions" },
                    [
                      _vm.areAssessmentWeights
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                "aria-label": _vm.$t("clearAllLabel", {
                                  text: _vm.$t("assessmentWeight")
                                })
                              },
                              on: { click: _vm.clearAssessmentWeights }
                            },
                            [_c("v-icon", [_vm._v("fal fa-trash-alt")])],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            "aria-label": _vm.$t("addItemLabel", {
                              text: _vm.$t("assessmentWeight")
                            })
                          },
                          on: {
                            click: function($event) {
                              return _vm.addAssessmentWeight()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("fal fa-plus")])],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "editor-section-container-body px-5 mt-4 mb-4"
                  },
                  [
                    _vm.areAssessmentWeights
                      ? _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "8" } }, [
                              _c("div", [_vm._v(_vm._s(_vm.$t("typeLabel")))])
                            ]),
                            _c("v-col", { attrs: { cols: "4" } }, [
                              _c("div", [
                                _vm._v(_vm._s(_vm.$t("percentageLabel")))
                              ])
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(_vm.filteredAssessmentWeights, function(weight, i) {
                      return _c(
                        "v-row",
                        { key: i, staticClass: "mb-0 mt-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-1 pt-1 pr-1",
                              attrs: { cols: "8" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "auto",
                                  rules: _vm.nameRulesAssessment,
                                  outlined: "",
                                  dense: "",
                                  "aria-label":
                                    _vm.$t("assessmentWeight") +
                                    " " +
                                    _vm.$t("typeLabel") +
                                    " " +
                                    i
                                },
                                model: {
                                  value: weight.name,
                                  callback: function($$v) {
                                    _vm.$set(weight, "name", $$v)
                                  },
                                  expression: "weight.name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-1 pt-1 pl-1",
                              attrs: { cols: "4" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "append-outer-icon": "fal fa-trash-alt",
                                  "hide-details": "auto",
                                  rules: _vm.percentRulesAssessment,
                                  outlined: "",
                                  dense: "",
                                  "aria-label":
                                    _vm.$t("assessmentWeight") +
                                    " " +
                                    _vm.$t("percentageLabel") +
                                    " " +
                                    i
                                },
                                on: {
                                  "click:append-outer": function($event) {
                                    return _vm.removeAssessment(i)
                                  }
                                },
                                model: {
                                  value: weight.percentage,
                                  callback: function($$v) {
                                    _vm.$set(weight, "percentage", $$v)
                                  },
                                  expression: "weight.percentage"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                ),
                _c("div", { staticClass: "editor-section-container-header" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("standardsLabel")))
                  ]),
                  _c(
                    "div",
                    { staticClass: "actions" },
                    [
                      _vm.areStandardWeights
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                "aria-label": _vm.$t("clearAllLabel", {
                                  text: _vm.$t("standardWeight")
                                })
                              },
                              on: { click: _vm.clearStandardWeights }
                            },
                            [_c("v-icon", [_vm._v("fal fa-trash-alt")])],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            "aria-label": _vm.$t("addItemLabel", {
                              text: _vm.$t("standardWeight")
                            })
                          },
                          on: {
                            click: function($event) {
                              return _vm.addStandardWeight()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("fal fa-plus")])],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "editor-section-container-body px-5 mt-4 mb-4"
                  },
                  [
                    _vm.areStandardWeights
                      ? _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "4" } }, [
                              _c("div", [_vm._v(_vm._s(_vm.$t("idLabel")))])
                            ]),
                            _c("v-col", { attrs: { cols: "8" } }, [
                              _c("div", [_vm._v(_vm._s(_vm.$t("nameLabel")))])
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(_vm.filteredStandardWeights, function(weight, i) {
                      return _c(
                        "v-row",
                        { key: i, staticClass: "mb-0 mt-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-1 pt-1 pr-1",
                              attrs: { cols: "4" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: "",
                                  "aria-label":
                                    _vm.$t("standardWeight") +
                                    " " +
                                    _vm.$t("idLabel") +
                                    " " +
                                    i
                                },
                                model: {
                                  value: weight.code,
                                  callback: function($$v) {
                                    _vm.$set(weight, "code", $$v)
                                  },
                                  expression: "weight.code"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-1 pt-1 pl-1",
                              attrs: { cols: "8" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "append-outer-icon": "fal fa-trash-alt",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: "",
                                  "aria-label":
                                    _vm.$t("standardWeight") +
                                    " " +
                                    _vm.$t("nameLabel") +
                                    " " +
                                    i
                                },
                                on: {
                                  "click:append-outer": function($event) {
                                    return _vm.removeStandard(i)
                                  }
                                },
                                model: {
                                  value: weight.name,
                                  callback: function($$v) {
                                    _vm.$set(weight, "name", $$v)
                                  },
                                  expression: "weight.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }